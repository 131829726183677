import React from "react";
import "./menu.css";
import logo from "./../../assets/logo.png";
import { FiAlignRight } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { Link } from "react-router-dom";
import photo1 from "./../../assets/1.jpeg";
import photo2 from "./../../assets/2.jpeg";
import photo3 from "./../../assets/3.jpeg";
import photo4 from "./../../assets/4.jpeg";
import photo5 from "./../../assets/5.jpeg";
import photo6 from "./../../assets/6.jpeg";
import photo7 from "./../../assets/7.jpeg";
import photo8 from "./../../assets/8.jpeg";
import photo9 from "./../../assets/9.jpeg";
import photo10 from "./../../assets/10.jpeg";
import photo11 from "./../../assets/11.jpeg";
import photo12 from "./../../assets/12.jpeg";
import $ from "jquery";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaFacebook, FaInstagram, FaTripadvisor } from "react-icons/fa";

const photos = [
    photo1,
    photo2,
    photo3,
    photo4,
    photo5,
    photo6,
    photo7,
    photo8,
    photo9,
    photo10,
    photo11,
    photo12,
  ];

const Menu = () => {

    const scrolltop = () => {
        window.scrollTo(0,0)
    }

    AOS.init();

    $(document).ready(function(){
        $("#menu").click(function(){
            $("#navbody").show();

        });
        $("#close").click(function(){
            $("#navbody").hide();
        });
    });

    return (
        <>
        <section className="menuhome">
            <header>
                <img src={logo} alt="" />
                <ul>
                    <Link to="/" onClick={scrolltop}><li>Home</li></Link>
                    <Link to="/#about"><li>About</li></Link>
                    <Link to="/menu"><li>Menu</li></Link>
                    <Link to="/#restaurantsection"><li>Restaurant</li></Link>
                </ul>
                <a href="tel: +38345481140"><button className="primary">+383 45 481 140</button></a>
            </header>

            <nav>
                <div id="navheader" className="firstheader">
                    <img src={logo} alt="" />
                    <FiAlignRight className="iconnav" id="menu"/>
                </div>
                <div id="navbody">
                    <div id="navheader">
                        <img src={logo} alt="" />
                        <FiX className="iconnav" id="close"/>
                    </div>
                    <div>
                        <ul>
                            <Link to="/" onClick={scrolltop}><li>Home</li></Link>
                            <Link to="/#about"><li>About</li></Link>
                            <Link to="/menu"><li>Menu</li></Link>
                            <Link to="/#restaurantsection"><li>Restaurant</li></Link>
                            <a href="tel: +38345481140"><li><button className="primary">+383 45 481 140</button></li></a>
                        </ul>
                    </div>
                </div>
            </nav>

            <div id="homemenupart">
                <p data-aos="fade-down" data-aos-duration="600">Menu</p>
            </div>
        </section>

        <section id="menus">
        {photos.map((photo, index) => (
        <img
          key={index}
          src={photo}
          alt={`Photo ${index + 1}`}
        />
      ))}
        </section>

        <footer>
            <div><img src={logo} alt="" /></div>
            <div>
                <p>CONTACT INFO</p>
                <a href="tel: +38345481140">+383 45 481 140</a>
            </div>
            <div>
                <p>LOCATION</p>
                <a href="https://www.google.com/maps/place/Restaurant+Qingji/@42.2320673,20.7496214,17z/data=!3m1!4b1!4m6!3m5!1s0x135395b316caf919:0xda34083cdd1e30f!8m2!3d42.2320673!4d20.7496214!16s%2Fg%2F11ll3t3x7w?entry=ttu">ALI PASHE TEPELENA, PRIZREN</a>
            </div>
            <div>
                <p>SOCIALS</p>
                <div id="socials">
                    <a href="https://www.facebook.com/p/Restaurant-Qingji-100077603777110/"><FaFacebook className="iconfooter" /></a>
                    <a href="https://www.instagram.com/restaurantqingji"><FaInstagram className="iconfooter" /></a>
                    <a href="https://en.tripadvisor.com.hk/Restaurant_Review-g304090-d25567740-Reviews-Restaurant_Familjar_Qingji-Prizren.html"><FaTripadvisor className="iconfooter" /></a>
                </div>
            </div>
        </footer>
        <div id="copyright">Copyright © {new Date().getFullYear()} Restaurant Qingji</div>
        </>
    );
}
 
export default Menu;