import './App.css';
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Menu from './components/Menu/Menu';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
