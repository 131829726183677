import "./home.css";
import logo from "./../../assets/logo.png";
import restaurant from "./../../assets/restaurant.png";
import tavolina from "./../../assets/tavolina.png";
import ramstek2 from "./../../assets/ramstek2.jpg";
import pleskavic2 from "./../../assets/pleskavic2.jpg";
import fli from "./../../assets/fli.jpeg";
import home2 from "./../../assets/home2.png";
import oda from "./../../assets/oda.jpeg";
import odalandscape from "./../../assets/odalandscape.jpeg";
import salla from "./../../assets/salla.jpeg";
import banner from "./../../assets/banner.jpeg";
import { FiAlignRight } from "react-icons/fi";
import { FiX } from "react-icons/fi";
import { FaFacebook, FaInstagram, FaTripadvisor } from "react-icons/fa";
import $ from "jquery";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from "react-router-dom";

const Home = () => {

    AOS.init();

    $(document).ready(function(){
        $("#menu").click(function(){
            $("#navbody").show();

        });
        $("#close, .linksclose").click(function(){
            $("#navbody").hide();
        });
    });

    const scrolltop = () => {
        window.scrollTo(0,0)
    }

    return (
        <>
        <section id="home">
            <header>
                <img src={logo} alt="" />
                <ul>
                    <Link to="/" onClick={scrolltop}><li>Home</li></Link>
                    <a href="#about"><li>About</li></a>
                    <Link to="/menu"><li>Menu</li></Link>
                    <a href="#restaurantsection"><li>Restaurant</li></a>
                </ul>
                <a href="tel: +38345481140"><button className="primary">+383 45 481 140</button></a>
            </header>

            <nav>
                <div id="navheader" className="firstheader">
                    <img src={logo} alt="" />
                    <FiAlignRight className="iconnav" id="menu"/>
                </div>
                <div id="navbody">
                    <div id="navheader">
                        <img src={logo} alt="" />
                        <FiX className="iconnav" id="close"/>
                    </div>
                    <div>
                        <ul>
                            <Link to="/" onClick={scrolltop}><li>Home</li></Link>
                            <a href="#about" className="linksclose"><li>About</li></a>
                            <Link to="/menu"><li>Menu</li></Link>
                            <a href="#restaurantsection" className="linksclose"><li>Restaurant</li></a>
                            <a href="tel: +38345481140"><li><button className="primary">+383 45 481 140</button></li></a>
                        </ul>
                    </div>
                </div>
            </nav>

            <div id="homepart">
                <p data-aos="fade-down" data-aos-duration="1000">Welcome to</p>
                <h1 data-aos="fade-down" data-aos-duration="1000">Restaurant Qingji</h1>
                <br />
                <h5>MAKING THE DELICIOUS PREMIUM FOOD SINCE 1990</h5>
                <h5>BOOK YOUR PLACE BY CALLING <span>+383 45 481 140</span></h5>
            </div>
        </section>

        <section id="about">
            <div id="restaurantphoto">
                <img src={restaurant} alt="" />
                <div className="blackshadow"></div>
            </div>
            <div id="tavolinaphoto">
                <img src={tavolina} alt="" />
                <div className="blackshadow"></div>
            </div>
            <div id="aboutdiv" data-aos="zoom-in" data-aos-duration="600">
                <p>DISCOVER</p>
                <h1>OUR STORY</h1>
                <br />
                <h6>Family Restaurant "Qingji" started work in 2016 with one hall and 4 employees (family). The preparation of food like at home and the warm reception like a family sent Qingji to a completely different level. Today Qingji has a total of 22 workers 6 Halls, 5 rooms for tourists and full of love and respect from Family Customers. The words about Qingji are endless, but we invite you to take the family and visit to convince yourself that the traditional always prevails and the family wins..!</h6>
                </div>
        </section>

        <section id="amazing">
            <p>AMAZING</p>
            <h1>DELICIOUS</h1>
        </section>

        <section className="menusection">
            <div id="menuitems">
                <div>
                    <img src={ramstek2} alt="" />
                </div>
                <div>
                    <img src={pleskavic2} alt="" />
                </div>
                <div>
                    <img src={fli} id="fliimg" alt="" />
                </div>
                <div>
                    <img src={home2} alt="" />
                </div>
            </div>
            <div className="menucheckout" data-aos="zoom-in" data-aos-duration="600">
                <div>
                <p>CHECK OUT</p>
                <h1>OUR MENU</h1>
                </div>

                <div>
                <h5>Ali Pashe Tepelena, Prizren 20000</h5>
                <b><h5>Tel: +383 45 481 140</h5></b>
                </div>

                <Link to="/menu" onClick={scrolltop}>VIEW MENU</Link>
            </div>
        </section>

        <section className="menusection" id="restaurantsection">
            <div id="menuitems">
                <div>
                    <img src={banner} alt="" />
                </div>
                <div>
                    <img src={oda} alt="" />
                </div>
                <div>
                    <img src={odalandscape} id="fliimg" alt="" />
                </div>
                <div>
                    <img src={salla} alt="" />
                </div>
            </div>
            <div className="menucheckout" id="restaurantcheckout" data-aos="zoom-in" data-aos-duration="600">
                <div>
                <p>VISIT OUR</p>
                <h1>RESTAURANT</h1>
                </div>

                <div>
                <h5>MAKING DELICIOUS PREMIUM</h5>
                <b><h5>FOOD SINCE 1990</h5></b>
                </div>

                <a href="https://www.google.com/maps/place/Restaurant+Qingji/@42.2320673,20.7496214,17z/data=!3m1!4b1!4m6!3m5!1s0x135395b316caf919:0xda34083cdd1e30f!8m2!3d42.2320673!4d20.7496214!16s%2Fg%2F11ll3t3x7w?entry=ttu">GET LOCATION</a>
            </div>
        </section>

        <section id="map">
            <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2954.175320775322!2d20.749621399999995!3d42.2320673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x135395b316caf919%3A0xda34083cdd1e30f!2sRestaurant%20Qingji!5e0!3m2!1sen!2s!4v1709828587513!5m2!1sen!2s" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>

        <footer>
            <div><img src={logo} alt="" /></div>
            <div>
                <p>CONTACT INFO</p>
                <a href="tel: +38345481140">+383 45 481 140</a>
            </div>
            <div>
                <p>LOCATION</p>
                <a href="https://www.google.com/maps/place/Restaurant+Qingji/@42.2320673,20.7496214,17z/data=!3m1!4b1!4m6!3m5!1s0x135395b316caf919:0xda34083cdd1e30f!8m2!3d42.2320673!4d20.7496214!16s%2Fg%2F11ll3t3x7w?entry=ttu">ALI PASHE TEPELENA, PRIZREN</a>
            </div>
            <div>
                <p>SOCIALS</p>
                <div id="socials">
                    <a href="https://www.facebook.com/p/Restaurant-Qingji-100077603777110/"><FaFacebook className="iconfooter" /></a>
                    <a href="https://www.instagram.com/restaurantqingji"><FaInstagram className="iconfooter" /></a>
                    <a href="https://en.tripadvisor.com.hk/Restaurant_Review-g304090-d25567740-Reviews-Restaurant_Familjar_Qingji-Prizren.html"><FaTripadvisor className="iconfooter" /></a>
                </div>
            </div>
        </footer>
        <div id="copyright">Copyright © {new Date().getFullYear()} Restaurant Qingji</div>
        </>
    );
}
 
export default Home;